import { useDispatch,useSelector } from "react-redux"
import { useEffect } from "react"
import { fetchStores } from "../../../redux/actions/store_actions"
import store from "../../../store";
import { Link } from "react-router-dom";

export default function BrandList({brands}) {

    return (
        <>
        {
            <ol class="list-group " >
                {
                    
                    brands.map((store)=>(
                        <a href={`/brand/detail/${store.id}`}>
                            <li key={store.id} class="list-group-item d-flex justify-content-between align-items-center">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold">{store.brand_name}</div>
                                
                            </div>
                            {/* <span class="badge primary-bg rounded-pill">{store}</span> */}
                        </li>
                        </a>
                    ))
                }
            </ol>
        }
        
        </>
    )
}