export default function Footer() {
    return (
        <>
            <div style={{ backgroundColor: "hsl(211deg 100% 12%)" }}>
                <div class="container">

                    <footer
                        class="text-center text-lg-start text-white"
                        style={{ backgroundColor: 'hsl(211deg 100% 12%)' }}
                    >

                        <section
                            class="d-flex justify-content-between p-4"
                            style={{ backgroundColo: "#6351ce" }}
                        >

                            <div class="me-5">
                                <span>Get connected with us on social networks:</span>
                            </div>

                            <div>
                                <a href="" class="text-white me-4">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a href="" class="text-white me-4">
                                    <i class="fab fa-twitter"></i>
                                </a>
                                <a href="" class="text-white me-4">
                                    <i class="fab fa-google"></i>
                                </a>
                                <a href="" class="text-white me-4">
                                    <i class="fab fa-instagram"></i>
                                </a>
                                <a href="" class="text-white me-4">
                                    <i class="fab fa-linkedin"></i>
                                </a>
                                <a href="" class="text-white me-4">
                                    <i class="fab fa-github"></i>
                                </a>
                            </div>

                        </section>

                        <section class="">
                            <div class="container text-center text-md-start mt-5">

                                <div class="row mt-3">

                                    <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                                        <h6 class="text-uppercase fw-bold">Contact with Us</h6>
                                        <hr
                                            class="mb-4 mt-0 d-inline-block mx-auto"
                                            style={{ width: "60px", backgroudColor: '#7c4dff', height: "2px" }}
                                        />
                                        <p><i class="fas fa-home mr-3"></i> New York, NY 10012, US</p>
                                        <p><i class="fas fa-envelope mr-3"></i> info@example.com</p>
                                        <p><i class="fas fa-phone mr-3"></i> + 01 234 567 88</p>
                                        <p><i class="fas fa-print mr-3"></i> + 01 234 567 89</p>
                                    </div>

                                    <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                                        <h6 class="text-uppercase fw-bold">Extras</h6>
                                        <hr
                                            class="mb-4 mt-0 d-inline-block mx-auto"
                                            style={{ width: "60px", backgroudColor: '#7c4dff', height: "2px" }}
                                        />
                                        <p>
                                            <a href="#!" class="text-white">Brands</a>
                                        </p>
                                        <p>
                                            <a href="#!" class="text-white">Stores</a>
                                        </p>
                                        <p>
                                            <a href="#!" class="text-white">Categoreis</a>
                                        </p>
                                    </div>

                                    <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                                        <h6 class="text-uppercase fw-bold">Information</h6>
                                        <hr
                                            class="mb-4 mt-0 d-inline-block mx-auto"
                                            style={{ width: "60px", backgroudColor: '#7c4dff', height: "2px" }}
                                        />
                                        <p>
                                            <a href="#!" class="text-white">About Us</a>
                                        </p>
                                        <p>
                                            <a href="#!" class="text-white">Privacy Policy</a>
                                        </p>
                                        <p>
                                            <a href="#!" class="text-white">Terms & Conditions</a>
                                        </p>
                                        <p>
                                            <a href="#!" class="text-white">Contact Us</a>
                                        </p>
                                    </div>

                                    <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

                                        <h6 class="text-uppercase fw-bold">Useful links</h6>
                                        <hr
                                            class="mb-4 mt-0 d-inline-block mx-auto"
                                            style={{ width: "60px", backgroudColor: '#7c4dff', height: "2px" }}
                                        />
                                        <p>
                                            <a href="#!" class="text-white">Your Account</a>
                                        </p>
                                        <p>
                                            <a href="#!" class="text-white">Become an Affiliate</a>
                                        </p>
                                        <p>
                                            <a href="#!" class="text-white">Shipping Rates</a>
                                        </p>
                                        <p>
                                            <a href="#!" class="text-white">Help</a>
                                        </p>
                                    </div>



                                </div>

                            </div>
                        </section>

                        <div
                            class="text-center p-3"
                            style={{ backgroundColor: "hsl(211deg 100% 12%)" }}
                        >
                            © Powered By :
                            <a class="text-white" href="https://mdbootstrap.com/"
                            >z.com © 2020 </a
                            >
                        </div>

                    </footer>


                </div>
            </div>
        </>
    )
}