export default function VerifyAccount(){
    return(
        <>
            <div className="container-fluid primary-bg d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
               
                <div className="card p-4 shadow p-3 mb-5 bg-white rounded" style={{maxWidth :500}}> 
                <div className="">
                    <h3>
                        Thank You for Joining Z Trade
                    </h3>
                    <img  width={200} height={200} src="https://cdn-icons-png.flaticon.com/512/4949/4949214.png"/>
                    <p>
                        Before you Start it, you need to veriy email,
                        We sent verification link to your email
                    </p>
                </div>
                </div>
            </div>
        </>
    );
}