
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import ShopProductCard from './ShopProductCard';
import { fetchMostPopular, fetchNewArrival, fetchProducts } from '../../redux/actions/product_actions';


const options = {
    margin: 30,
    responsiveClass: 1,
    nav: 1,
    dots: false,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 5,

        }
    },
};

export default function NewArrival({ carouselID, newArrivalProduct }) {

    const state= {
        responsive:{
            0: {
                items: 2,
                nav : 0
            },
            450: {
                items: 2,
               nav: 0
            },
            600: {
                items: 3,
                nav:1
            },
            1000: {
                items: 6,
                nav:1
            },
        },
    }
    const dispatch = useDispatch();
    const newArrivalProducts = useSelector((state) => state.newArrival.newArrivalProducts);
    const isLoading = useSelector((state) => state.newArrival.loading);

    const handleClick = () =>(
        console.log("nedtt")
    )

    useEffect(() => {
        dispatch(fetchNewArrival());
    }, [])

    return (

        <>
            {

                isLoading ?
                    "Loading"
                    :
                    <div className="container mt-4">

                        <div className="row">
                            <div className="col-lg-6 d-flex justify-content-start e">

                                <h3 className=''>
                                    <i class=" custom-color fa-solid fa-square "></i> New Arrival
                                </h3>

                            </div>




                            {
                                newArrivalProduct.length <= 0 ?

                                    <div>
                                        No New Arrival Yet
                                    </div>
                                    :

                                    <>
                                        {/* <div className="col-lg-6 tablet">
                                            <div className="d-flex justify-content-end">
                                                <div className="me-4">
                                                    <button className={`ms-1 carousel-button ${newArrivalProducts.data.length <= 8 ? 'disabled' : ''}`} href={`${newArrivalProducts.data.length <= 8 ? '' : '#carouselExampleControls' + carouselID}`} role="button" data-slide="prev" >
                                                        <i class="fa-solid fa-angle-left text-white ms-2 me-2"></i>
                                                    </button>
                                                    <button className={`ms-1 carousel-button mr-5 ${newArrivalProducts.data.length <= 8 ? 'disabled' : ''}`} href={`${newArrivalProducts.data.length <= 8 ? '' : '#carouselExampleControls' + carouselID}`} role="button" data-slide="next">
                                                        <i class="fa-solid fa-angle-right text-white ms-2 me-2"></i>
                                                    </button>
                                                </div>


                                            </div>

                                        </div> */}

                                        {/* <div className="col-lg-6 tablet">
                                            <div className="d-flex justify-content-end ">
                                                <div className="me-4 ">
                                                    
                                                    <button type="button" className="customNextBtn" onClick={handleClick}>next</button>

                                                </div>


                                            </div>

                                        </div> */}

                                        {/* <div className="col-lg-6 mobile-hide">
                                            <div className="d-flex justify-content-end ">
                                                <div className="me-4">
                                                    <button className={`ms-1 carousel-button `} href={`${'#carouselExampleControls' + carouselID}`} role="button" data-slide="prev" >
                                                        <i class="fa-solid fa-angle-left text-white ms-2 me-2"></i>
                                                    </button>
                                                    <button id="customNextBtn" className={`ms-1 carousel-button mr-5 customNextBtn`}  role="button" >
                                                        <i class="fa-solid fa-angle-right text-white ms-2 me-2 "></i>
                                                    </button>
                                                </div>


                                            </div>

                                        </div> */}
                                    </>
                            }

                        </div>

                        <hr />

                        <div className='container'>
                            <OwlCarousel navText={
                            [  '<span class="owl-prev btn btn-primary-color">‹</span>',
                                '<span class="owl-next btn btn-primary-color">›</span>']
                            } 
                            
                            nav={1} dots={1} className='' loop={newArrivalProducts.data.length <= 7 ? false : true} items={newArrivalProducts.data.length <= 5 ? newArrivalProducts.data.length : 5} 
                            responsive={state.responsive}
                            
                            >

                                {
                                    newArrivalProduct.map((product, index) => (
                                        <div key={product.id} className="item">
                                            <ShopProductCard product={product} index={index} />
                                        </div>
                                    ))
                                }

                            </OwlCarousel>
                            {/* <Slider  {...settings}>
                            <div className="">
                                {
                                    newArrivalProducts.data.map((product, index) => (
                                        <div key={product.id} className="">
                                            <ShopProductCard product={product} index={index} />
                                        </div>
                                    ))
                                }
                            </div>
                        </Slider> */}
                        </div>

                        {/* <div id={"carouselExampleControls" + carouselID} class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="cards-wrapper">
                                    {
                                        newArrivalProducts.data.slice(0, 8).map((product, index) => (
                                            <div key={product.id} className="">
                                                <ShopProductCard product={product} index={index} />
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                            <div class="carousel-item">
                                <div class="cards-wrapper">
                                    {
                                        newArrivalProducts.data.slice(7, 15).map((product, index) => (
                                            <div key={product.id} className="">
                                                <ShopProductCard product={product} index={index} />
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        </div>


                    </div> */}
                    </div>

            }

        </>
    )
}