import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { fetchStores } from '../../redux/actions/store_actions';
import { Link } from 'react-router-dom';

export default function BrandSlider(){

    const dispatch = useDispatch();
    const stores = useSelector((state) => state.store.stores);

    useEffect(()=>{
        dispatch(fetchStores())
    },[])

    const images = [
        "https://global.canon/00cmn/img/common/ogp-logo.png",
        "https://i.pinimg.com/originals/0e/9e/df/0e9edf68a71c691ba32b5e88847588f8.png",
        "https://image3.mouthshut.com/images/imagesp/925004846s.jpg",
        "https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Mercedes-Logo.svg/800px-Mercedes-Logo.svg.png",
        "https://www.sony.net/top/2017/img/icon/top-og.jpg",
        "https://i0.wp.com/www.theoasg.com/wp-content/uploads/2016/01/FUni.png?ssl=1",
        "https://play-lh.googleusercontent.com/EdP_VUMKSywQp49WuzDChNHNlzSwXZR0mqsy8LkOtWYZWXMFZgzQXv95P1oWCgibas4"
    ];

    return (
        <div className='container'>
            <div className="row mt-5">
                <div className="col-lg-12 d-flex justify-content-start ">
                    
                    <h3 className=''>
                        <i class="custom-color fa-solid fa-square"></i> Brands
                    </h3>
                    
                </div>
            </div>
            <hr></hr>
           {

            stores.length <=0 ?

            <div>
                No Brands yet
            </div>
            :

             <OwlCarousel className='owl-theme' loop={stores.length <= 7 ? false : true} margin={10} nav items={stores.length <= 7 ? stores.length : 7}>
                
             {
                 stores.map((store,index)=>(
                     <a href={`/brand/detail/${store.id}`} state={{data:store}}>
                     <div key={store.id} class="item">                            
                        <img src={process.env.REACT_APP_STATUS === "development" ?
                                 `http://127.0.0.1:8000/storage/store_image/${store.image}`
                                 :
                                 `${process.env.REACT_APP_PRODUCTION_PORT}/storage/store_image/${store.image}`} className="card-img-top p-3" style={{width:"150px"}}/>
                     </div>
                     </a>
                 ))
             }
         </OwlCarousel>
           }
        </div>
    );
}