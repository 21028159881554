import { Link, useNavigate } from "react-router-dom";
import { deleteUserCookie, getUserCookie } from "../../cookie/cookie";


export default function TopBar(){
    const navigate = useNavigate();
    const logout = ()=>{
        deleteUserCookie("token")
        deleteUserCookie("session_id")
        navigate('/');
    }

    return (
        <>
        <div className="secondary-bg">
        <div className="container custom-navbar d-flex justify-content-between align-items-center">
            <ul className="mt-2">
                <li>
                    <i class="fa-brands fa-facebook"></i>
                </li>
                <li>
                    <i class="fa-brands fa-instagram"></i>
                </li>
                <li>
                    <i class="fa-brands fa-twitter"></i>
                </li>
                <li>
                    <i class="fa-brands fa-youtube"></i>
                </li>
            </ul>
            <ul className="mt-2">
                {
                    getUserCookie("token") === null ?
                    <>
                    <li>
                    <span className="fs-6">
                        <a href="/login" className="text-decoration-none link fw-bold text-dark">Login</a>
                        
                    </span>
                    
                </li>

                <li>
                
                    <span className="fs-6"><a href="/register" className="link text-decoration-none  fw-bold text-dark">Register</a></span>
                    
                </li>
                    </>

                    :
                    <>
                    <li>
                    <span className="fs-6"><Link to="/profile" className="text-decoration-none text-dark">My Profile</Link></span>                    
                    </li>
                    <li>
                    <span className="fs-6"><Link className="text-decoration-none text-dark" onClick={logout}>Log out</Link></span>                    
                    </li>
                    </>

                }
                
            </ul>
        </div>
        </div>
        </>
    )
}