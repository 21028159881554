

import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { getUserCookie } from "../../cookie/cookie";
import { fetchCategories } from "../../redux/actions/category_actions";

export default function Navbar() {

    const dispatch = useDispatch();



    const categories = useSelector((state) => state.categories.categories);
    useEffect(() => {
        dispatch(fetchCategories())

    }, [])

    return (
        <div className="primary-bg">
            <div className="container">
            <nav className="navbar navbar-expand-lg navbar-dark primary-bg">
                <div className="container-fluid">
                    
                    <button className="navbar-toggler mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="main_nav">


                        <ul className="navbar-nav d-flex align-items-center" >

                        <li className="nav-item dropdown ">

                                <a className="nav-link menu-container d-flex align-items-center text-white fw-bold" href="#" data-bs-toggle="dropdown" >  
                                    <i className="fa-solid fa-list m-3"></i><span className="pr-2 custom-padding-left"> Categories</span> <i className="fa-solid fa-caret-down custom-padding-left "></i>
                                  </a>


                                <ul className="dropdown-menu">
                                    {
                                        categories.map((data)=>(
                                            <li>
                                                <Link to={`/categories/${data.id}`} className="dropdown-item" href="#"> 
                                                {data.name} {data.sub_category.length > 0 ? <i class="fa-solid fa-caret-right"></i>  : ""}
                                                </Link>
                                                {

                                                    data.sub_category.length > 0 ?
                                                    <ul className="submenu dropdown-menu">
                                                    {
                                                        data.sub_category.map((data)=>(
                                                        
                                                            <li><Link className="dropdown-item" to={`/subcategory/${data.id}`}>{data.name}</Link></li>
                                                        ))
                                                    }
                                                    </ul>
                                                    :
                                                    ""
                                                }
                                                
                                            </li>
                                        ))
                                    }
                                    {/* <li><a className="dropdown-item" href="#"> Dropdown item 1 </a></li>
                                    <li><a className="dropdown-item" href="#"> Dropdown item 2 &raquo; </a>
                                        <ul className="submenu dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Submenu item 1</a></li>
                                            <li><a className="dropdown-item" href="#">Submenu item 2</a></li>
                                            <li><a className="dropdown-item" href="#">Submenu item 3</a></li>
                                        </ul>
                                    </li>
                                    <li><a className="dropdown-item" href="#"> Dropdown item 3 &raquo; </a>
                                        <ul className="submenu dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Another submenu 1</a></li>
                                            <li><a className="dropdown-item" href="#">Another submenu 2</a></li>
                                            <li><a className="dropdown-item" href="#">Another submenu 3</a></li>
                                            <li><a className="dropdown-item" href="#">Another submenu 4</a></li>
                                        </ul>
                                    </li>
                                    <li><a className="dropdown-item" href="#"> Dropdown item 4 &raquo;</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Another submenu 1</a></li>
                                            <li><a className="dropdown-item" href="#">Another submenu 2</a></li>
                                            <li><a className="dropdown-item" href="#">Another submenu 3</a></li>
                                            <li><a className="dropdown-item" href="#">Another submenu 4</a></li>
                                        </ul>
                                    </li>
                                    <li><a className="dropdown-item" href="#"> Dropdown item 5 </a></li>
                                    <li><a className="dropdown-item" href="#"> Dropdown item 6 </a></li> */}
                                </ul>
                            </li>

                            <li className="nav-item active"> <Link className="nav-link fw-bold text-white" to="/">Home </Link> </li>
                            <li className="nav-item fw-bold "><Link className={getUserCookie("token") === null ? "nav-link fw-bold text-grey " : "nav-link fw-bold text-white "  } data-bs-toggle="tooltip" data-bs-placement="top" title="Need Login to view Favourites Items" to="#"> Favourites </Link></li>
                            {/* <li className="nav-item fw-bold"><Link className="nav-link fw-bold text-white" href="#"> About Us </Link></li>
                            <li className="nav-item fw-bold"><Link className="nav-link fw-bold text-white" href="#"> Brands </Link></li> */}
                            
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        </div>
    )
}