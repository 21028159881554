import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchSingleCategory } from "../../redux/actions/category_actions";

import Footer from "../../shared/components/Footer";
import MiddleBar from "../../shared/components/MiddleBar";
import Navbar from "../../shared/components/Navbar";
import Products from "../../shared/components/Products";
import TopBar from "../../shared/components/TopBar";
import Setting from "../brand/components/Setting";
import CategoryProductList from "./components/CategoryProductList";
import SideCategory from "./components/SideCategory";

export default function CategoryDetail() {

    let { categoryId } = useParams();

    return (
        <>
            {/* <TopBar />
            <MiddleBar />
            <Navbar /> */}

                <div style={{ backgroundColor: "hsl(0deg 0% 96%)" }}>
                <div className="container mt-2">
                    <div className="row g-0">
                        <div className="col-lg-3 padding-left ms-3 mt-3">
                            <SideCategory />
                        </div>
                        <div className="col-lg-8">


                            <div className="mt-3">
                                <Setting />
                            </div>

                            <CategoryProductList categoryId={categoryId} col="col-lg-3"/>
                            {/* <Products title="Category Products" col="col-lg-3" /> */}

                        </div>



                    </div>

                </div>
            </div>
            
            <Footer/>
        </>
    )
}