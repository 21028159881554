import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { fetchProducts } from "../../redux/actions/product_actions"
import ShopProductCard from "./ShopProductCard"

export default function Products({title,col,data}) {

    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.products);

    useEffect(()=>{
        dispatch(fetchProducts())
    },[])

    return (
        <>
            <div className="container">
            <div className="row mt-3">
                <div className="col-12 d-flex justify-content-start ">
                    
                    <h3 className=''>
                        <i class="custom-color fa-solid fa-square"></i> {title}
                    </h3>
                    
                </div>
            </div>
            <hr></hr>
                
                <div class="row g-0">
                    {
                        products.map((product, index) => (
                            <div key={product.id} className={col} >                               
                                
                                <ShopProductCard product={product} index={index} />
                                
                            </div>
                        ))
                    }
                </div>
                
                
            </div>
        </>
    )
}