export const ActionTypes = {
    INDEX : "INDEX",
    FETCH_CATEGORIES : "FETCH_CATEGORIES",
    FETCH_SINGLE_CATEGORY : "FETCH_SINGLE_CATEGORY",
    FETCH_SUB_CATEGORIES : "FETCH_SUB_CATEGORIES",
    FETCH_SUB_SINGLE_CATEGORY : "FETCH_SUB_SINGLE_CATEGORY",
    FETCH_PERCENT : "FETCH_PERCENT",
    FETCH_NEW_ARRIVAL : "FETCH_NEW_ARRIVAL",
    FETCH_MOST_POPULAR : "FETCH_MOST_POPULAR",
    UPDATE_PERCENT : "UPDATE_PERCENT",
    FETCH_SINGLE_PRODUCT : "FETCH_SINGLE_PRODUCT",
    GET_SEARCH : "GET_SEARCH",
    SEARCH_CLEAN_UP: "SEARCH_CLEAN_UP",
    FETCH_SLIDERS : "FETCH_SLIDERS",
    ADD_SLIDER : "ADD_SLIDER",
    ADD_USER: "ADD_USER",
    USER_LOADING: "USER_LOADING",
    USER_LOADED: "USER_LOADED",
    LOGIN_SUCCESS : "LOGIN_SUCCESS",
    AUTH_MESSAGE : "AUTH_MESSAGE",
    AUTH_CLEAN_UP : "AUTH_CLEAN_UP",
    FETCH_STORES : "FETCH_STORES",
    FETCH_SINGLE_STORE : "FETCH_SINGLE_STORE",
    ERROR_MESSAGE : "ERROR_MESSAGE",
    SUCCESS_MESSAGE : "SUCCESS_MESSAGE",
    CATEGORY_CLEAN_UP : "CATEGORY_CLEAN_UP",
    SLIDER_CLEAN_UP : "SLIDER_CLEAN_UP",
    STORE_CLEAN_UP : "STORE_CLEAN_UP",
    STORE_MESSAGE : "STORE_MESSAGE",
    FETCH_BANNERS : "FETCH_BANNERS",
    ADD_BANNER : "ADD_BANNER",
    DELETE_BANNER : "DELETE_BANNER",
    BANNER_ERROR_MESSAGE : "BANNER_ERROR_MESSAGE",
    BANNER_CLEAN_UP : "BANNER_CLEAN_UP",
    FETCH_PRODUCTS : "FETCH_PRODUCTS",
    FETCH_SINGLE_PRODUCT : "FETCH_SINGLE_PRODUCT"
}