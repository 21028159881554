import {combineReducers} from 'redux';
import MostPopular from '../shared/components/MostPopular';
import { BannerReducer } from './reducers/banner_reducer';
import { CategoryReducer, SingleCategoryReducer, SingleSubCategoryReducer } from './reducers/category_reducers';
import { IndexReducer } from './reducers/index_reducer';
import { PercentReducer } from './reducers/percent_reducer';
import { MostPopularReducer, NewArrivalReducer, ProductReducer, SingleProductReducer } from './reducers/product_reducer';
import { SearchReducer } from './reducers/search_reducer';
import { SliderReducer } from './reducers/slider_reducers';
import { SingleStoreReducer, StoreReducer } from './reducers/store_reducers';
import { UserReducer } from './reducers/user_reducers';

const reducers = combineReducers({
    categories : CategoryReducer,
    singleCategory : SingleCategoryReducer,
    singleStore : SingleStoreReducer,
    newArrival : NewArrivalReducer,
    mostPopular : MostPopularReducer,
    percent : PercentReducer,
    slider : SliderReducer,
    store : StoreReducer,
    banner : BannerReducer,
    product : ProductReducer,
    singleProduct : SingleProductReducer,
    user : UserReducer,
    singleSubCategory : SingleSubCategoryReducer,
    index : IndexReducer,
    search : SearchReducer,
});

export default reducers;