import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react";
import {
    useLocation,
    useParams
  } from "react-router-dom";
import { fetchSingleProduct } from "../../../redux/actions/product_actions";
export default function ProductDescriptionLinkShare({  product }) {

    // const product = useSelector((state) => state.product.product);
    const isProductLoading = useSelector((state) => state.singleProduct.loading);
    let { productId } = useParams();
    const singleProduct = useSelector((state) => state.singleProduct.product);
    const dispatch = useDispatch()
    // const { name, item_description, price, item_id, store } = product;
    // const image = `${process.env.REACT_APP_PRODUCTION_PORT}storage/product_image/` + product.product_image[0].thumbnails.replace(/["]+/g, '');
    const [thumbnail, setThumbnail] = useState("");

    const _handleThumnnail = (data) => {
        setThumbnail(`${process.env.REACT_APP_PRODUCTION_PORT}storage/product_image/` + data)
    }

    useEffect(() => {
        console.log(product)
        dispatch(fetchSingleProduct(productId))
        window.scrollTo(0, 0)
        if(!isProductLoading){
            setThumbnail(`${process.env.REACT_APP_PRODUCTION_PORT}storage/product_image/` + singleProduct.data.product_image[0].thumbnails.replace(/["]+/g, ''))

        }
        // setThumbnail(`${process.env.REACT_APP_PRODUCTION_PORT}storage/product_image/` + product.product_image[0].thumbnails.replace(/["]+/g, ''))
    }, [singleProduct && singleProduct.status])

    return (
        <>
            {
                isProductLoading === true ?
                    "Loading"
                    :
                    <>
                        <div className="container mt-5" style={{ backgroundColor: "hsl(0deg 0% 96%)" }}>
                            <div className="row pt-3 pb-3">
                                <div className="col-lg-5">
                                    <div className="row">
                                        <div className="col-lg-12 mt-3">
                                            <img className="product-image" src={thumbnail} />

                                        </div>

                                    </div>

                                    <div className="mt-3 d-flex justify-content-center align-items-center">
                                        {
                                            singleProduct.data.product_image.map((data) => (
                                                <div className="">
                                                    <div className="product-image-small border" style=
                                                        {{
                                                            backgroundImage: `url(
                                                ${process.env.REACT_APP_PRODUCTION_PORT}storage/product_image/${data.thumbnails.replace(/["]+/g, '')}
                                            )`

                                                        }}
                                                        onClick={(e) => _handleThumnnail(data.thumbnails.replace(/["]+/g, ''))}
                                                    />

                                                </div>
                                            ))
                                        }
                                        {/* <div className="col-lg-3">
                                    <img className="product-image-small" src={

                                        process.env.REACT_APP_STATUS === "development" ?

                                        "http://127.0.0.1:8000/storage/product_image/"

                                    }/>
    
                                </div> */}
                                        {/* <div className="col-lg-3">
                                    <div className="product-image-small" style={{backgroundImage : "url(https://food.fnr.sndimg.com/content/dam/images/food/products/2022/5/31/rx_monogram-water-bottle.jpeg.rend.hgtvcom.616.924.suffix/1654027407906.jpeg)"}}/>
    
                                </div>
    
                                <div className="col-lg-3">
                                    <div className="product-image-small" style={{backgroundImage: "url(https://cdn.shopify.com/s/files/1/0251/9410/2837/products/OceanBottle_OG_Front_Sunshine-Orange_2048px.jpg?v=1653562257&width=533)"}}/>
    
                                </div>
    
                                <div className="col-lg-3">
                                    <div className="product-image-small" style={{backgroundImage: "url(http://cdn.shopify.com/s/files/1/0251/9410/2837/products/OceanBottle_OG_Front_Ocean-Blue_2048px.jpg?v=1653562177)"}}/>
    
                                </div> */}

                                    </div>


                                </div>

                                <div className="col-lg-7">

                                    <div className="d-flex justify-content-start">
                                        <h3>{singleProduct.data.name}</h3>
                                    </div>
                                    <hr></hr>

                                    <dl class="row">
                                        <dt class="col-sm-3 fs-5 text-start">Brand : </dt>
                                        <dd class="col-sm-9 fs-5 text-start">{singleProduct.data.store === null ? "ND" : "ND"}</dd>

                                        <dt class="col-sm-3 fs-5 text-start">Product ID :</dt>
                                        <dd class="col-sm-9 fs-5 text-start">
                                            <p>{singleProduct.data.item_id}</p>
                                            
                                        </dd>

                                        <dt class="col-sm-3 fs-5 text-start">Price</dt>
                                        <dd class="col-sm-9 fs-5 text-start">{singleProduct.data.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MMK</dd>

                                    </dl>

                                    {/* <div className="row">
                                        <div className="row">
                                            <div className="col-lg-3 col-sm-3 fs-5 d-flex justify-content-start">
                                                <p>Brand :</p>
                                            </div>
                                            <div className="col-lg-8 col-sm-8 fs-5">
                                                {product.store === null ? "ND" : "ND"}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 fs-5 d-flex justify-content-start">
                                                <p>Product ID :</p>
                                            </div>
                                            <div className="col-lg-8 fs-5">
                                                {item_id}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 fs-5 d-flex justify-content-start">
                                                <p>Price :</p>
                                            </div>
                                            <div className="col-lg-8 fs-5">
                                                {price} MMK
                                            </div>
                                        </div>
                                    </div> */}

                                    <hr />

                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Description</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Additional Information</button>
                                        </li>

                                    </ul>
                                    <div class="tab-content" id="myTabContent" style={{ backgroundColor: "#fff" }}>
                                        <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                            <p className="text-justify lh-base p-3">
                                                {singleProduct.data.item_description}
                                            </p>
                                        </div>
                                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                                            <p className="text-justify lh-base p-3">
                                                {singleProduct.data.item_description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}