import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../../shared/components/Footer";
import Setting from "../../brand/components/Setting";
import SideCategory from "../../category/components/SideCategory";




import SubCategoryProductList from "./SubCategoryProductList";

export default function SubCategoryDetail() {

    let { categoryId } = useParams();

    return (
        <>
            {/* <TopBar />
            <MiddleBar />
            <Navbar /> */}

                <div style={{ backgroundColor: "hsl(0deg 0% 96%)" }}>
                <div className="container mt-2">
                    <div className="row">
                        <div className="col-lg-3 padding-left ms-3 mt-3">
                            <SideCategory />
                        </div>
                        <div className="col-lg-8">


                            <div className="mt-3">
                                <Setting />
                            </div>

                            <SubCategoryProductList categoryId={categoryId} col="col-lg-3"/>
                            {/* <Products title="Category Products" col="col-lg-3" /> */}

                        </div>



                    </div>

                </div>
            </div>
            
            <Footer/>
        </>
    )
}