import { ActionTypes } from "../actions/types";
import { setUserCookie,getUserCookie } from "../../cookie/cookie";

const userInitialState={
    token: getUserCookie("token"),
    isAuthenticated: null,
    isLoading: true,
    user: null,
    error : false,
    errorMessage : []
}


export const UserReducer = (state = userInitialState, {type, payload}) => {

    switch (type){
        case ActionTypes.USER_LOADING:
            return{
                ...state,
                isLoading: true,
            }
        case ActionTypes.AUTH_MESSAGE:
            return{
                ...state,
                isloading : false,
                error : true,
                errorMessage : payload
            }
        case ActionTypes.AUTH_CLEAN_UP:
            return{
                ... state,
                loading : false,
                error : false,
                errorMessage : []
            }
        case ActionTypes.USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: payload,
            }
       
        case ActionTypes.LOGIN_SUCCESS:
        // case ActionTypes.ADD_USER:

            var d = new Date();
            var n = d.getDay();
            setUserCookie("token",JSON.stringify(payload.token),1);
            setUserCookie("session_id",JSON.stringify(payload.user.id),1);
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user : payload
            }
        default:
            return state;
    }
};