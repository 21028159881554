import { useState,useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { fetchSingleCategory } from "../../../redux/actions/category_actions";
import { fetchProducts } from "../../../redux/actions/product_actions";
import ShopProductCard from "../../../shared/components/ShopProductCard";
import { useParams } from "react-router-dom";

export default function CategoryProductList({categoryId, col }) {
    
    const dispatch = useDispatch();
    const category = useSelector((state) => state.singleCategory.category);
    const isLoading = useSelector((state) => state.singleCategory.loading);

    useEffect(() => {
        dispatch(fetchSingleCategory(categoryId))        
    }, [categoryId])  

    return (
        <>
            {
                isLoading === true  ?
                    "Loading"
                    :
                    <div className="container">
                        <div className="row mt-3">
                            <div className="col-lg-12 d-flex justify-content-start ">

                                <h3 className=''>
                                    <i class="fa-solid fa-square"></i>{category.name}
                                </h3>

                            </div>
                        </div>
                        <hr></hr>

                        <div class="row g-0">
                            {
                                category.product.map((data, index) => (
                                    <div key={data.id} className={col}>
                                        <ShopProductCard product={data} index={index} />
                                    </div>
                                ))
                            }
                        </div>


                    </div>
            }
        </>
    )
}