import { ActionTypes } from "../actions/types"

const indexInitialState ={
    loading : true,
    data: [],
    error : false,
    errorMessage : []
}


export const IndexReducer = (state = indexInitialState,{type,payload}={})=>{
    switch(type){
        case ActionTypes.INDEX:
            return {
                ... state,
                loading : false,
                data  : payload
            }
        default:
            return state;
    }
}