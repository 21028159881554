export default function SkeletonLoader() {
    return (
        <div className="container">
            <div className="row">
                {Array(6).fill(1).map((el, i) =>
                    <>
                        <div className="col-lg-2 col-md-6 col-sm-4 pb-1">
                            <a class="card" id="card-link" target="_blank" >
                                <div class="card__body">
                                   
                                    <div class="card__body body__img">
                                        <img class="skeleton" alt="" id="cover-img" />
                                    </div>
                                </div>

                                <div class="card__footer text-center" id="card-footer">
                                    <div class="skeleton skeleton-text skeleton-footer "></div>
                                </div>
                            </a>
                        </div>
                    </>
                )}
            </div>

        </div>
    )
}