export default function Setting() {
    return (
        <>
            <div className="container bg-white">

                <div className="d-flex justify-content-between align-items-center p-2 rounded-3">
                    <h5>
                        
                    </h5>
                    <div class="dropdown">
                        Sort By :   
                        <button class="ms-3 btn btn-secondary-outline border btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Price (Desending)
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" href="#">Price (Asending)</a></li>
                            
                        </ul>
                    </div>
                    

                    
                </div>

            </div>
        </>
    )
}