import { ActionTypes } from "../actions/types"


const searchInitialState ={
    loading : true,
    searchData : [],   
    error : false,
    errorMessage : []
}

export const SearchReducer = (state = searchInitialState,{type,payload}={})=>{
    switch(type){
        case ActionTypes.GET_SEARCH:
            return {
                ... state,
                loading : false,
                searchData  : payload
            }
        case ActionTypes.SEARCH_CLEAN_UP:
            return {
                ... state,
                loading : true,
                searchData : []
            }

        default:
            return state;
    }
}
