export default function MiniCard(){
   
    return(
        
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 border">
                        <div className="row mt-3 mb-3">
                            <div className="col-3 d-flex align-items-center justify-content-end">
                            <i class="fa-regular fa-face-smile fa-2xl icon-color"></i>
                            </div>
                            <div className="col-8 ">
                                <span class="fs-6 fw-bold d-flex justify-content-start">100 % SETISFACTION</span>
                                <span class="d-flex justify-content-start">If you are unable</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 border">
                        <div className="row mt-3 mb-3">
                            <div className="col-3 d-flex align-items-center justify-content-end">
                            <i class="fa-regular fa-credit-card fa-2xl icon-color"></i>
                            </div>
                            <div className="col-9 ">
                                <span class="fs-6 fw-bold  d-flex justify-content-start">SAVE 20% WHEN YOU</span>
                                <span class="d-flex justify-content-start">use Credit Card</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 border">
                        <div className="row mt-3 mb-3">
                            <div className="col-3 d-flex align-items-center justify-content-end">
                            <i class="fa-solid fa-ship fa-2xl icon-color"></i>
                            </div>
                            <div className="col-9 ">
                                <span class="fs-6 fw-bold  d-flex justify-content-start">FAST FREE SHIPMENT</span>
                                <span class="d-flex justify-content-lg-center justify-content-xl-start justify-content-md-center">Load any Items</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 border">
                        <div className="row mt-3 mb-3">
                            <div className="col-3 d-flex align-items-center justify-content-end">
                            <i class="fa-sharp fa-solid fa-dollar-sign fa-2xl icon-color"></i>
                            </div>
                            <div className="col-9 ">
                                <span class="fs-6 fw-bold  d-flex justify-content-start">14 DAYS MONEY BACK</span>
                                <span class="d-flex justify-content-start">If you are unable</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}