import { useSelector } from "react-redux"
import { Link } from "react-router-dom";



export default function SideCategory(){

    const categories = useSelector((state) => state.categories.categories);

    return(
        <>
        <ol class="list-group " >

            {
                categories.map((category)=>(
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">
                        <Link to={`/categories/${category.id}`}>
                        {category.name}
                        </Link>

                        </div>
                        
                    </div>
                    <span class="badge primary-bg rounded-pill">{category.product.length}</span>
                </li>
                ))
            }
                
                {/* <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Category Name</div>
                        
                    </div>
                    <span class="badge primary-bg rounded-pill">14</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Category Name</div>
                        
                    </div>
                    <span class="badge primary-bg rounded-pill">14</span>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Category Name</div>
                        
                    </div>
                    <span class="badge primary-bg rounded-pill">14</span>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Category Name</div>
                        
                    </div>
                    <span class="badge primary-bg rounded-pill">14</span>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Category Name</div>
                        
                    </div>
                    <span class="badge primary-bg rounded-pill">14</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Category Name</div>
                        
                    </div>
                    <span class="badge primary-bg rounded-pill">14</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Category Name</div>
                        
                    </div>
                    <span class="badge primary-bg rounded-pill">14</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Category Name</div>
                        
                    </div>
                    <span class="badge primary-bg rounded-pill">14</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Category Name</div>
                        
                    </div>
                    <span class="badge primary-bg rounded-pill">14</span>
                </li> */}
            </ol>
        </>
    )
}