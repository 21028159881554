import ztrade_api from "../../api/ztrade_api";
import { ActionTypes } from "./types";



export const fetchProducts = () => async (dispatch) => {
  const response = await ztrade_api.get("nonrole/product/list")
  .then(res=>{
    dispatch({type:ActionTypes.FETCH_PRODUCTS,payload:res.data   })
  }).catch(err=>{
    dispatch({type:ActionTypes.FETCH_PRODUCTS,payload:[]  })
  });    
}

export const fetchNewArrival = () => async (dispatch) => {
  const response = await ztrade_api.get("nonrole/newarrival")
  .then(res=>{
    dispatch({type:ActionTypes.FETCH_NEW_ARRIVAL,payload:res.data   })
  }).catch(err=>{
    dispatch({type:ActionTypes.FETCH_NEW_ARRIVAL,payload:[]  })
  });    
}

export const fetchMostPopular = () => async (dispatch) => {
  const response = await ztrade_api.get("nonrole/mostpopular")
  .then(res=>{
    dispatch({type:ActionTypes.FETCH_MOST_POPULAR,payload:res.data   })
  }).catch(err=>{
    dispatch({type:ActionTypes.FETCH_MOST_POPULAR,payload:[]  })
  });    
}

export const fetchSingleProduct = (id) => async (dispatch) => {
  const response = await ztrade_api.get("nonrole/product/show/"+id)
  .then(res=>{
    dispatch({type:ActionTypes.FETCH_SINGLE_PRODUCT,payload:res.data   })
  }).catch(err=>{
    dispatch({type:ActionTypes.FETCH_SINGLE_PRODUCT,payload:[]  })
  });    
}