import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import ProductDetail from './pages/product/ProductDetail';
import Brand from './pages/brand/BrandDetail';
import CategoryDetail from './pages/category/CategoryDetail';
import Login from './pages/authentication/Login';
import Register from './pages/authentication/Register';
import VerifyAccount from './pages/verify_account/verify_accout';
import TopBar from './shared/components/TopBar';
import MiddleBar from './shared/components/MiddleBar';
import Navbar from './shared/components/Navbar';
import { getUserCookie } from './cookie/cookie';
import BrandLists from './pages/brand/BrandLists';
import SubCategoryDetail from './pages/sub_category/components/SubCategoryDetail';
import Search from './pages/search/Search';
import Profile from './pages/profile/Profile';



function App() {
  return (
    <div className="App">
      <BrowserRouter>

        {
          getUserCookie('token') !== null ?
            <>
              <TopBar />
              <MiddleBar />
              <Navbar />
              <Routes>
                <Route exact path="/" element={<Home />}>

                </Route>

                {/* <Route path="/brand/detail" element={<Brand/>}></Route> */}

                {/* <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Register />}></Route> */}
                <Route path="/profile/" element={<Profile />}></Route>
                <Route path="/categories/:categoryId" element={<CategoryDetail />}></Route>
                <Route path="/subcategory/:categoryId" element={<SubCategoryDetail />}></Route>
                <Route path="/productdetail/:productId" element={<ProductDetail />}></Route>
                
                <Route path="/brand/detail/:brandId" element={<Brand />}></Route>
                <Route path="/verifyemail" element={<VerifyAccount />}></Route>
              </Routes>
            </>
            :
            <>

            {
              window.location.href.includes('login')
              ?
              <Routes>
              <Route path="/login" element={<Login />}></Route>
              
              </Routes>
              :
              window.location.href.includes('register')
              ?
              <Routes>
              <Route path="/register" element={<Register />}></Route>
              </Routes>
              :
              <>
              <TopBar />
              <MiddleBar />
              <Navbar />
          
              <Routes>
                <Route exact path="/" element={<Home />}>

                </Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/search/" element={<Search />}></Route>
                <Route path="/brands/" element={<BrandLists />}></Route>
                
                <Route path="/categories/:categoryId" element={<CategoryDetail />}></Route>
                <Route path="/subcategory/:categoryId" element={<SubCategoryDetail />}></Route>
                <Route path="/productdetail/:productId" element={<ProductDetail />}></Route>                
                <Route path="/brand/detail/:brandId" element={<Brand />}></Route>
                <Route path="/verifyemail" element={<VerifyAccount />}></Route>
              </Routes>
              </>

            }
              
            </>

        }

      </BrowserRouter>
    </div>
  );
}

export default App;
