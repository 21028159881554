import { ActionTypes } from "./types";
import ztrade_api from "../../api/ztrade_api";
import { getUserCookie } from "../../cookie/cookie";


// export const postUser =  (data) => async (dispatch) => {    
//     const response = await talentAndJobsAPI.post("api/login/email",data).then(res =>{
//       dispatch({type: ActionTypes.ADD_USER,
//         payload : res.data})
//     })
//     .catch(err =>{
//     //   dispatch(returnErrors(err.response.data,err.response.status));
      
//     })   
// }  

export const registerNewUser = (data) => async (dispatch) => {
  const response = await ztrade_api.post("nonrole/user/register",data).then(
    res => {
      if(res.status === 201){
        process.env.REACT_APP_STATUS === "development"  ? 
        window.location.href = "http://localhost:4000/verifyemail" 
        : 
        window.location.href = process.env.REACT_APP_WEB_PRODUCTION_PORT + "verifyemail"
      }
      else{
        dispatch({type:ActionTypes.AUTH_MESSAGE, payload : res.console.error})
      }
    }
  ).catch(err => {
    dispatch({type:ActionTypes.AUTH_MESSAGE, payload : err.response.data})
    // dispatch(returnErrors(err.response.data,err.response.status));
  })
}


export const loginUser = (data) => async (dispatch) => {
  const response = await ztrade_api.post("nonrole/login",data).then(
    res => {
      if(res.status === 201){
        dispatch({type:ActionTypes.LOGIN_SUCCESS,payload: res.data})
        process.env.REACT_APP_STATUS === "development"  ? 
        window.location.href = "http://localhost:4000/" 
        : 
        window.location.href = process.env.REACT_APP_WEB_PRODUCTION_PORT
      }
      if(res.status === 401){
        window.alert("User is not Verified")
      }
      else{
        dispatch({type:ActionTypes.AUTH_MESSAGE, payload : res.console.error})
      }
    }
  ).catch(err => {
    dispatch({type:ActionTypes.AUTH_MESSAGE, payload : err.response.data})
    // dispatch(returnErrors(err.response.data,err.response.status));
  })
}

export const loadUser = () => async (dispatch)=>{
  
  dispatch({type:ActionTypes.USER_LOADING})  
  const response = ztrade_api.get('api/user/show/'+getUserCookie("session_id"),tokenConfig(getUserCookie("token"))).then(res => {
    dispatch({
      type: ActionTypes.USER_LOADED,
      payload: res.data
    })
  }).catch(err =>{
    console.log("User Loaded Error");
    // console.log(err.response.data)
  })
}


export const updateUser = (data) => async (dispatch) =>{
  const response = await ztrade_api.post("api/user/update/"+getUserCookie("session_id"),data).then(
    res => {
      if(res.status === 201){
        // dispatch({type:ActionTypes.LOGIN_SUCCESS,payload: res.data})
        process.env.REACT_APP_STATUS === "development"  ? 
        window.location.href = "http://localhost:4000/profile" 
        : 
        window.location.href = process.env.REACT_APP_WEB_PRODUCTION_PORT +"profile"
      }
      if(res.status === 401){
        window.alert("User is not Verified")
      }
      else{
        dispatch({type:ActionTypes.AUTH_MESSAGE, payload : res.console.error})
      }
    }
  ).catch(err => {
    dispatch({type:ActionTypes.AUTH_MESSAGE, payload : err.response.data})
    // dispatch(returnErrors(err.response.data,err.response.status));
  })
}


export const authCleanUp = () => (dispatch) =>{
  dispatch({type:ActionTypes.AUTH_CLEAN_UP,payload: ""})
}


export const tokenConfig =(token) =>{
 
    const userToken = JSON.parse(token)
    const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
    if(userToken) {
        config.headers['Authorization'] = `Bearer  ${userToken}`;
    }

    return config;
}