import Banner from "../../shared/components/Banner";
import BrandSlider from "../../shared/components/BrandSlider";
import Footer from "../../shared/components/Footer";
import MiddleBar from "../../shared/components/MiddleBar";
import MiniCard from "../../shared/components/MiniCard";
import Navbar from "../../shared/components/Navbar";
import Products from "../../shared/components/Products";
import ProductSlider from "../../shared/components/ProductSlider";
import Slider from "../../shared/components/Slider";
import TopBar from "../../shared/components/TopBar";
import Category from "../category/Category";
import CateogryComponent from "../category/components/Category_Component";
import { useDispatch,useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { fetchCategories } from "../../redux/actions/category_actions";
import { fetchProducts } from "../../redux/actions/product_actions";
import { fetchBanners } from "../../redux/actions/banner_actions";
import NewArrival from "../../shared/components/NewArrival";
import MostPopular from "../../shared/components/MostPopular";
import { index } from "../../redux/actions/index_action";

export default function Home(){

    const dispatch = useDispatch();

    const categories = useSelector((state) => state.categories.categories);
    const loading = useSelector((state) => state.categories.loading);
    const products = useSelector((state) => state.product.products);
    const isProductLoading = useSelector((state) => state.product.loading);
    const banners = useSelector((state) => state.banner.banners);
    const isBannerLoading = useSelector((state) => state.banner.loading);

    const indexLoading = useSelector((state) => state.index.loading);

    const indexData = useSelector((state) => state.index.data);
    const [bannerOne, setBannerOne] = useState([]);
    const [bannerTwo, setBannerTwo] = useState([]);

    


    useEffect(()=>{
        dispatch(index())
        // dispatch(fetchCategories())
        // dispatch(fetchProducts())
        // dispatch(fetchBanners())  
        
    },[])
    return(
        
            <>
            
            {

                indexLoading 
                ?
                "Loading"
                :
                <div>
            {/* <TopBar/>
            <MiddleBar/>
            <Navbar categories={categories}/> */}
           
            <Slider isBrand={false} sliderData={indexData.sliders}/>
            
            
            <Category categories={indexData.categories} loading={indexLoading}/>
            <MiniCard/>
            <ProductSlider title={"Top Selling Product"} carouselID="2" products={indexData.topselling} isLoading={indexLoading}/>
            {
                indexData.banners.length > 2 && indexData.banners.length == 4
                ?
                <Banner banners={indexData.banners.slice(0,2)} isLoading={isBannerLoading}> </Banner>
                :
                indexData.banners.length <=1 ?
                <Banner banners={indexData.banners} isLoading={isBannerLoading}> </Banner>
                :
                indexData.banners.length  == 2 ?
                <Banner banners={indexData.banners} isLoading={isBannerLoading}> </Banner>
                :
                <Banner banners={indexData.banners.slice(0,2)} isLoading={isBannerLoading}> </Banner>

            }
            {/* <Banner/> */}
            <BrandSlider/>
            <NewArrival carouselID="3" newArrivalProduct={indexData.newarrival}/>
            <MostPopular carouselID="4" mostPopularProduct={indexData.mostpopular}/>
            {/* <ProductSlider title={"New Arrival"} carouselID="3" products={products} isLoading={isProductLoading}/> */}

            {
                indexData.banners.length > 2 && indexData.banners.length == 4
                ?
                <Banner banners={indexData.banners.slice(2,4)} isLoading={isBannerLoading}> </Banner>
                :
                indexData.banners.length <=1 ?
                <Banner banners={indexData.banners} isLoading={isBannerLoading}> </Banner>
                :
                indexData.banners.length  == 2 ?
                <Banner banners={indexData.banners} isLoading={isBannerLoading}> </Banner>
                :
                <Banner banners={indexData.banners.slice(2,4)} isLoading={isBannerLoading}> </Banner>

            }
            
            {/* <ProductSlider title={"Most Popular"} carouselID="4" products={products} isLoading={isProductLoading}/> */}
            <Products title="Latest Upload" col="col-lg-3 col-xl-2 col-sm-4 col-6 col-md-3" product={indexData.products}/>
            <Footer/>

        </div>
            }
            </>
        
    )
}