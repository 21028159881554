import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSearch } from "../../redux/actions/search_actions"
import Footer from "../../shared/components/Footer";
import Products from "../../shared/components/Products";
import ShopProductCard from "../../shared/components/ShopProductCard";
import SideCategory from "../category/components/SideCategory";




export default function Search(){
    const searchData = useSelector((state) => state.search.searchData);
    const searchLoading = useSelector((state) => state.search.loading);
    return(
        <>
        {
            searchLoading ?
            "Loading"
            :
            <div className="container">

                <div class="row mt-3">
                <div className="col-lg-3 padding-left ms-3 ">
                <SideCategory />
                </div>
                   <div className="col-lg-8">
                        <div className="row g-0">
                        {
                        searchData.map((product, index) => (
                            <div key={product.id} className="col-lg-3 col-xl-3 col-sm-4 col-5 col-md-3" >                               
                                
                                <ShopProductCard product={product} index={index} />
                                
                            </div>
                        ))
                    }
                        </div>
                   </div>
                </div>
            </div>
        }
        <Footer/>
        </>

    )
}