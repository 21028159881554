import { Link } from "react-router-dom";

export default function CateogryComponent({categories}) {
   
    return (
        <>
            <div className="container">
                <div className="row">
                    <div class="container-fluid pt-5">
                        <div class="text-center mb-4">
                            <h2 class="section-title px-5"><span class="px-2">Product Categories</span></h2>
                        </div>
                        <div class="row text-center">
                          
                           {
                                categories.slice(0,12).map((data, index) => {
                                    return (
                                        <div class="col-lg-2 col-md-6 col-sm-4 pb-1 m-2" >
                                            <div class="cat-item d-flex flex-column mb-4 shadow-sm" style={{ maxHeight : "200px", height :"200px",padding: "30px", backgroundColor: "#fff" }}>

                                                <Link to={`/categories/${data.id}`} class="cat-img position-relative overflow-hidden mb-3">
                                                    <img className="img-fluid" src={
                                                        
                                                        process.env.REACT_APP_STATUS === "development" ?
                                                        `${process.env.REACT_APP_DEVELOPMENT_PORT}storage/category_image/${data.image}`
                                                        :
                                                        `${process.env.REACT_APP_PRODUCTION_PORT}storage/category_image/${data.image}`

                                                    } alt={data.name} 
                                                    
                                                    // style={{
                                                    //     backgroundImage: process.env.REACT_APP_STATUS === "development" ?
                                                    //     `${process.env.REACT_APP_DEVELOPMENT_PORT}storage/category_image/${data.image}`
                                                    //     :
                                                    //     `${process.env.REACT_APP_PRODUCTION_PORT}storage/category_image/${data.image}`,
                                                    //     backgroundSize : "cover",
                                                    //     backgroudnRepeat : "no-repeat",
                                                    //     backgroundPosition : "center center"
                                                    // }}
                                                    />
                                                </Link>
                                              
                                                    <div className="d-flex align-items-center justify-content-center" style={{height:"80px"}}>
                                                    <h5 class="m-0 " style={{fontSize:"16px"}}>{data.name} ({data.product.length})</h5>
                                                    </div>
                                              
                                            </div>
                                        </div>
                                    )
                                })
                            }
                           
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}