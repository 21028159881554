import axios from "axios";
import ztrade_api from "../../api/ztrade_api";
import { getUserCookie } from "../../cookie/cookie";
import { ActionTypes } from "./types";

export const getSearch = (data) => async (dispatch) => {
    const response = await ztrade_api.get("api/search/"+data+"/null")
    .then(res=>{
      dispatch({type:ActionTypes.GET_SEARCH,payload:res.data   })
    }).catch(err=>{
       console.log(err)
    });    
}

export const tokenConfigFile =() =>{
 
  // const userToken = JSON.parse(token)
  const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    };
  // if(userToken) {
  //     config.headers['Authorization'] = `Bearer ${userToken}`;
  // }

  return config;
}