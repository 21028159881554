import { useState,useEffe } from "react";
import { Link } from "react-router-dom";
import { getUserCookie } from "../../cookie/cookie";


export default function ShopProductCard({ product, index }) {
  const [isLiked, setIsLike] = useState(false);
  const { id, name, price, item_description } = product;
  const  [image, setImage] = useState(`${process.env.REACT_APP_PRODUCTION_PORT}storage/product_image/` + product.product_image[0].thumbnails.replace(/["]+/g, ''));
  const liked = () => {
    if (isLiked) {
      setIsLike(false)
    }
    else {
      setIsLike(true)
    }

  }  
  return (

    <>
    <div className="thumb-wrapper">
      <div className="thumb-unit">
        <Link to={`/productdetail/${id}`} preventScrollReset={true} className="text-decoration-none" state={{ data: product }}>
          {/* <div className="heading mb-3"><span>{price} MMK</span><span><i class={ isLiked ? "fa-solid fa-heart fa-lg  heart"  : "fa fa-heart-o fa-lg " } onClick={liked}></i></span></div> */}
          <div className="box" style={{ backgroundImage: "url(" + image + ")", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
          <div className="info">
            <p> {name}</p>
          </div>
        </Link>
        <div className="heading mb-1 d-flex justify-content-center icon-color fw-bold">
          <span className=""   >{price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>          
        </div>
        <span class="badge mb-2 p-2 rounded-pill primary-bg">MMK</span>
        <div className=" d-flex justify-content-center">

          {

            getUserCookie('token') === null ?

            <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Login to add as a Favourite Item">
                <button class="btn btn-primary-color rounded" style={{pointerEvents : 'none'}} type="button" disabled>
                <i class="fa fa-heart-o fa-sm m-2" ></i>
                </button>
              </span>
              // <button className="btn btn-primary-color rounded" disabled data-toggle="tooltip" data-placement="top" title="Please Login to Add as Favourite Item">
              //   <span className='d-flex justify-content-center p-1'><i class={isLiked ? "fa-solid fa-heart fa-sm  heart m-2" : "fa fa-heart-o fa-sm m-2"} onClick={liked}></i></span>
              // </button>

              :
              <button className="btn btn-primary-color rounded" onClick={liked} data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                <span className='d-flex justify-content-center p-1'><i class={isLiked ? "fa-solid fa-heart fa-sm  heart m-2" : "fa fa-heart-o fa-sm m-2"} onClick={liked}></i></span>
              </button>
          }

          {/* <button className="btn btn-primary-color rounded" onClick={liked} data-toggle="tooltip" data-placement="top" title="Tooltip on top">
            <span className='d-flex justify-content-center p-1'><i class={isLiked ? "fa-solid fa-heart fa-sm  heart m-2" : "fa fa-heart-o fa-sm m-2"} onClick={liked}></i></span>
          </button> */}
        </div>
      </div>
    </div>
    </>

  );
}