import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, updateUser } from "../../redux/actions/user_actions";




export default function Profile() {

    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user);
    const userLoading = useSelector((state) => state.user.isLoading);
    const [name,setName] = useState("");
    const [factoryName, setFactoryName] = useState("");


    const handleUpdate = () =>{
        const data = {
            "name" : name,
            "factory_name" : factoryName
        }
        dispatch(updateUser(data))
    }

    useEffect(()=>{
        dispatch(loadUser())
    },[])

    return (
        <>
            {
                userLoading ?
                "Loading"
                :
                <>
                <div class="container rounded bg-white mt-5 mb-5">
                <div class="row d-flex justify-content-center">
                    <div class="col-md-3 border-right">
                        <div class="d-flex flex-column align-items-center text-center p-3 py-5">
                            <img class="rounded-circle mt-5" width="150px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"/>
                            <span class="font-weight-bold">{user.user.name}</span><span class="text-black-50">{user.user.email}</span><span> </span></div>
                    </div>
                    <div class="col-md-5 border-right">
                        <div class="p-3 py-5">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h4 class="text-right">My Profile</h4>
                            </div>
                            <div class="row mt-2 ">
                                <div class="col-md-12">
                                    <label class="labels">
                                        Name
                                    </label>
                                    <input type="text" class="form-control" placeholder="Your Name" defaultValue={user.user.name} onChange={e=>setName(e.target.value)}/>
                                </div>

                                <div class="col-md-12">
                                    <label class="labels">Email</label>
                                    <input type="email" class="form-control disabled" placeholder="example123@gmail.com" value={user.user.email}/>
                                </div>

                                <div class="col-md-12">
                                    <label class="labels">Factory Name</label>
                                    <input type="text" class="form-control" placeholder="Factory Name" defaultValue={user.user.factory_name} onChange={e=>setFactoryName(e.target.value)}/>
                                </div>
                            </div>
                            <div class="mt-5 text-center"><button class="btn btn-primary profile-button" type="button" onClick={handleUpdate}>Update Profile</button></div>
                        </div>
                    </div>
                    
                    </div>
            </div>
                </>
            }
           
        
        
        </>
    );
}