import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBanners } from "../../redux/actions/banner_actions";


export default function Banner({ banners }) {


    return (
        <>
            {

                <div className="container">
                <div className="row">

                    {
                        banners.map(banner => (
                            <>
                                <div className="banner col-md-6 col-sm-12 col-lg-6 col-12  banner-image">
                                    <img className="img-fluid m-2" src={

                                        process.env.REACT_APP_STATUS === "development" ?
                                            `${process.env.REACT_APP_DEVELOPMENT_PORT}storage/banner_image/${banner.image}`
                                            :
                                            `${process.env.REACT_APP_PRODUCTION_PORT}storage/banner_image/${banner.image}`

                                    } />
                                </div>
                            </>
                        ))
                    }

                    {/* <div className="banner col-lg-6">
                        <img className="img-fluid" src="https://previews.123rf.com/images/yaistantine/yaistantine1904/yaistantine190400007/126191211-2-sides-flyer-template-for-sale-promotion-with-sample-product-images-for-a4-paper-size.jpg" />
                    </div>
                    <div className="banner col-lg-6">
                        <img className="img-fluid" src="https://d341ezm4iqaae0.cloudfront.net/assets/2020/03/11165431/Annouce_Employee_Promotion02.jpg" />

                    </div> */}
                </div>
            </div>

            }

        </>
    );
}