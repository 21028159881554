import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../../src/Group_7.svg';
import { getSearch } from '../../redux/actions/search_actions';
import Autocomplete from './AutoComplete';

export default function MiddleBar() {

    const [category, setCategory] = useState("All");
    const [searchItem, setSearchItem] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector((state) => state.categories.categories);

    const onFormSubmit = (event) => {
        event.preventDefault();
       dispatch(getSearch(searchItem))
       navigate("/search");
    }

    return (
        <>
            <div className="container">
                <div className="middle-bar-div">
                    <div className="d-lg-none d-md-none d-flex justify-content-center align-items-center">
                        <img src={logo} alt="logo" className="d-block logo" />
                    </div>
                    <div className="d-flex  justify-content-sm-center  justify-content-md-between align-items-center">
                        <div className="d-none d-sm-block d-sm-none d-md-block">
                            <img src={logo} alt="logo" className="d-block" />
                        </div>
                        <form onSubmit={onFormSubmit} className=" d-flex  justify-content-center align-items-center" style={{width:"900px"}}>
                            <div className="col-lg-4 d-flex justify-content-md-between   align-items-center">
                                {/* <div class="dropdown ">
                                    <a class="btn btn-secondary-outline border dropdown-toggle rounded-0 d-inline-block text-truncate" style={{ maxWidth: "150px" }} href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        {category}
                                    </a>

                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li><a class="dropdown-item" href="#" onClick={e => setCategory("All")}>All</a></li>
                                        {
                                            categories.map(data => (
                                                <li><a class="dropdown-item" href="#" onClick={e => setCategory(data.name)}>{data.name}</a></li>
                                            ))
                                        }


                                    </ul>
                                </div> */}

                                <div class="input-group search-bar ">
                                    <Autocomplete suggestions={[]} state={setSearchItem}/>
                                    {/* <input type="text" onChange={e => setSearchItem(e.target.value)} class="form-control rounded-0" placeholder="Search Items" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                    <span class="input-group-text" id="basic-addon2"><i class="fa-solid fa-magnifying-glass"></i></span> */}


                                </div>

                            </div>
                        </form>

                        <div className="d-none d-sm-block d-sm-none d-md-block " style={{width:"400px"}}>
                            <span className="d-flex justify-content-end">
                                <i class="fa-solid fa-phone-volume mx-2 mt-1 "></i> Call Us : 0123456
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}