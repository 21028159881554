import ztrade_api from "../../api/ztrade_api";
import { ActionTypes } from "./types";

export const fetchCategories = () => async (dispatch) => {
    const response = await ztrade_api.get("nonrole/category/list")
    .then(res=>{
      dispatch({type:ActionTypes.FETCH_CATEGORIES,payload:res.data   })
    }).catch(err=>{
      dispatch({type:ActionTypes.FETCH_CATEGORIES,payload:[]  })
    });    
}

export const fetchSingleSubCategory = (id) => async (dispatch) => {
  const response = await ztrade_api.get("nonrole/subcategory/show/"+id)
  .then(res=>{
    dispatch({type:ActionTypes.FETCH_SUB_SINGLE_CATEGORY,payload:res.data   })
  }).catch(err=>{
    dispatch({type:ActionTypes.FETCH_SUB_SINGLE_CATEGORY,payload:err  })
  });    
}

export const fetchSingleCategory = (id) => async (dispatch) => {
  const response = await ztrade_api.get("nonrole/category/show/"+id)
  .then(res=>{
    dispatch({type:ActionTypes.FETCH_SINGLE_CATEGORY,payload:res.data   })
  }).catch(err=>{
    dispatch({type:ActionTypes.FETCH_SINGLE_CATEGORY,payload:err  })
  });    
}

export const cleanUp = () => (dispatch) =>{
  dispatch({type:ActionTypes.CATEGORY_CLEAN_UP,payload: ""})
}

export const fetchSubCategories = () => async (dispatch) => {
  const response = await ztrade_api.get("nonrole/subcategory/list")
  .then(res=>{
    dispatch({type:ActionTypes.FETCH_SUB_CATEGORIES,payload:res.data   })
  }).catch(err=>{
    dispatch({type:ActionTypes.FETCH_SUB_CATEGORIES,payload:[]  })
  });    
}



export const tokenConfigFile =() =>{
 
  // const userToken = JSON.parse(token)
  const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  // if(userToken) {
  //     config.headers['Authorization'] = `Bearer ${userToken}`;
  // }

  return config;
}