import { useState,useEffect } from "react"
import { useDispatch,useSelector } from "react-redux"
import { fetchCategories } from "../../redux/actions/category_actions"
import CateogryComponent from "./components/Category_Component";
import SkeletonLoader from "./components/SkeletionLoader";

export default function Category({categories,loading}){

   

    return(
        <>
            {loading
            ?
            <SkeletonLoader/>
            :
            <CateogryComponent categories={categories}/>
            }
           
        </>
    )
}