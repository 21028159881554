import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ztradeLogo from "../../images/login-logo.svg"
import { authCleanUp, loginUser } from "../../redux/actions/user_actions";

export default function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showLoading, setShowLoading] = useState(false)
    const dispatch = useDispatch();

    const isError = useSelector((state) => state.user.error);
    const errorMessage = useSelector((state) => state.user.errorMessage);

    const _handleSubmit = () => {
        const data = { email, password }
        setShowLoading(true)
        dispatch(loginUser(data))
    }

    useEffect(() => {


        return () => {
            setTimeout(() => {
                dispatch(authCleanUp())
                setShowLoading(false)
            }, 1000);

        }
    }, [isError])

    return (
        <div className="register-container"

        >
            {
                isError ?
                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Error!</strong> {errorMessage.message}
                        {/* <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> */}
                    </div>
                    :
                    ""
            }
            <div className="register-custom-form container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="d-block justify-content-center align-items-center login-logo">
                            <img src={ztradeLogo} className='mb-4' />
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <h1 className="login-heading">Log in to Z Trade<span className="full-stop">  </span></h1>
                                <h3 className="register-subheading">Don't have an account?<span className="link ms-3"><a href="/register" className="text-decoration-none text-white">Register Here</a></span></h3>
                            </div>
                        </div>
                        <div className="webflow-style-input">
                            <input className="" type="email" placeholder="example123@gmail.com" onChange={e => setEmail(e.target.value)}></input>
                        </div>
                        <div className="webflow-style-input">
                            <input className="" type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}></input>
                        </div>

                        <div className="register-buttons">
                            <div className="cancel">
                                <button className="" >
                                    <a href="/" className="text-decoration-none text-white">
                                        Go back 
                                    </a>    
                                </button>
                            </div>
                            <div className="submit">
                                <button className="" onClick={_handleSubmit}>

                                    {
                                        showLoading ?
                                            <span class="spinner-border spinner-border-sm mx-3" role="status" aria-hidden="true"></span>
                                            :
                                            ""
                                    }

                                    Log in</button>
                            </div>

                        </div>
                    </div>

                    {/* <div className="col-lg-6 ">
                    <div className="register-mission">
                        <img src={ztradeLogo}/>
                        <h1>Our Vision</h1>
                        <span>
                            To Become one of the best trading companies in Myanmar's industrial world.
                        </span>
                    </div>
                        
                </div> */}
                </div>
            </div>
        </div>
    )
}