import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { fetchProducts } from "../../../redux/actions/product_actions";
import ShopProductCard from "../../../shared/components/ShopProductCard";


export default function BrandProductList({ title, col, data }) {
    
    return (
        <>
            <div className="container">
                <div className="row mt-3">
                    <div className="col-lg-12 d-flex justify-content-start ">

                        <h3 className=''>
                            <i class="fa-solid fa-square"></i> {data.brand_name}
                        </h3>

                    </div>
                </div>
                <hr></hr>

                <div class="row ">
                    {
                        data.product.length === 0 ?
                            <div class="alert alert-danger" role="alert">
                                No Product For this Brand
                            </div>
                            :
                            <>
                                {
                                    data.product.map((product, index) => (
                                        <div key={product.id} className={col}>


                                            <ShopProductCard product={product} index={index} />

                                        </div>
                                    ))
                                }
                            </>
                    }
                </div>


            </div>
        </>
    )
}