import { useState,useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { fetchSingleSubCategory } from "../../../redux/actions/category_actions";
import ShopProductCard from "../../../shared/components/ShopProductCard";



export default function SubCategoryProductList({categoryId, col }) {
    
    const dispatch = useDispatch();
    const category = useSelector((state) => state.singleSubCategory.subCategory);
    const isLoading = useSelector((state) => state.singleSubCategory.loading);

    useEffect(() => {
        dispatch(fetchSingleSubCategory(categoryId))        
    }, [categoryId])  

    return (
        <>
            {
                isLoading === true  ?
                    "Loading"
                    :
                    <div className="container">
                        <div className="row mt-3">
                            <div className="col-lg-12 d-flex justify-content-start ">

                                <h3 className=''>
                                    <i class="fa-solid fa-square"></i>{category.data.name}
                                </h3>

                            </div>
                        </div>
                        <hr></hr>

                        <div class="row ">
                        

                        {

                                category.data.product.length === 0 ?
                                    <div class="alert alert-info" role="alert">
                                    No Item in this Sub Category
                                    </div>
                                :

                                category.data.product.map((data, index) => (
                                    <div key={data.id} className={col}>
                                        <ShopProductCard product={data} index={index} />
                                    </div>
                                ))
                            }
                        </div>


                    </div>
            }
        </>
    )
}