import Footer from "../../shared/components/Footer";
import MiddleBar from "../../shared/components/MiddleBar";
import Navbar from "../../shared/components/Navbar";
import Products from "../../shared/components/Products";
import ProductSlider from "../../shared/components/ProductSlider";
import Slider from "../../shared/components/Slider";
import TopBar from "../../shared/components/TopBar";
import BrandList from "./components/BrandLists";
import Setting from "./components/Setting";
import {

    useParams
  } from "react-router-dom";
  import { useEffect } from "react";
  import { useDispatch,useSelector } from "react-redux";
import { fetchSingleStore, fetchStores } from "../../redux/actions/store_actions";
import BrandProductList from "./components/BrandProductList";

export default function Brand() {   

    const dispatch = useDispatch()
    let { brandId } = useParams();
    const store = useSelector((state) => state.singleStore.store);
    const stores = useSelector((state) => state.store.stores);
    const isLoading = useSelector((state) => state.singleStore.loading);
    useEffect(()=>{
        dispatch(fetchSingleStore(brandId))
        dispatch(fetchStores())
    },[])

    return (
        <>
            {/* <TopBar />
            <MiddleBar />
            <Navbar /> */}
            <div style={{backgroundColor : "hsl(0deg 0% 96%)"}}>
                <div className="container mt-2">
                    <div className="row">
                        <div className="col-lg-3 padding-left ms-3 mt-3">
                            {
                                isLoading?
                                "Loading"
                                :
                                <BrandList brands={stores} />
                            }

                            
                        </div>
                        <div className="col-lg-8">
                            {
                                isLoading?
                                "Loading"
                                :
                                <Slider isBrand={true}  brandSlider={store.data.slider}/>
                            }
                                

                            <Setting />
                                {
                                    isLoading ?
                                    "loading"
                                    :
                                    <BrandProductList title={store && store.data} col="col-lg-4" data={store && store.data}/>

                                }                            
                        </div>
                       


                    </div>
                    
                </div>
            </div>
            <Footer/>
        </>
    )
}