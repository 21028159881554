import ztrade_api from "../../api/ztrade_api";
import { ActionTypes } from "./types";



export const fetchSlider = () => async (dispatch) => {
    const response = await ztrade_api.get("nonrole/slider/list")
    .then(res=>{
      dispatch({type:ActionTypes.FETCH_SLIDERS,payload:res.data   })
    }).catch(err=>{
      dispatch({type:ActionTypes.FETCH_SLIDERS,payload:[]  })
    });    
}



export const tokenConfigFile =() =>{
 
  // const userToken = JSON.parse(token)
  const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  // if(userToken) {
  //     config.headers['Authorization'] = `Bearer ${userToken}`;
  // }

  return config;
}