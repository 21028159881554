import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ztradeLogo from "../../images/Logo.svg"
import { authCleanUp, registerNewUser } from "../../redux/actions/user_actions";

export default function Register() {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [showLoading, setShowLoading] = useState(false)
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [factoryName, setFactoryName] = useState('');
    const [email, setEmail] = useState('');
    const userError = useSelector((state) => state.user.errorMessage);
    const isError = useSelector((state) => state.user.error);

    const dispatch = useDispatch()

    const handleRegister = () => {
        const data = {
            'name': userName,
            'email': email,
            'password': password
        }
        setShowLoading(true)

        dispatch(registerNewUser(data))

    }



    useEffect(() => {
        return () => {
            setTimeout(() => {
                dispatch(authCleanUp())
                setShowLoading(false)
            }, 2000);

        }
    }, [isError])

    return (
        <div className="">           

            <div className="register-container">
            <div className="container register-mission-mobile">
                <div className="row pt-2 pb-2">
                    <div className="col-3 d-flex align-items-center justify-content-center">
                        <img src={ztradeLogo} className="mobile-img-size"/>
                    </div>
                    <div className="col-9">
                    <h1 className="vision-heading">Our Vision</h1>
                        <span className="vision-text">
                            To Become one of the best trading companies in Myanmar's industrial world.
                        </span>
                    </div>
                </div>
            </div>
                <div className="register-custom-form">

                    {
                        isError ?
                            <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                <strong>Error!</strong> 
                                {userError.errors.name} &nbsp; 
                                {userError.errors.email}
                                {userError.errors.password}
                                {/* <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
                            </div>
                        :
                        ""
                }

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 className="register-heading">Create new Account<span className="full-stop">.</span></h1>
                                        <h3 className="register-subheading">Already a Member ?<span className=" ms-3"><a href="/login" className="text-decoration-none text-white"> Login</a></span></h3>
                                    </div>
                                </div>
                                <div className="webflow-style-input">
                                    <input className="" type="text" placeholder="Your Name" onChange={e => setUserName(e.target.value)}></input>

                                </div>
                                <div className="webflow-style-input">
                                    <input className="" type="text" placeholder="Factory Name"></input>
                                </div>
                                <div className="webflow-style-input">
                                    <input className="" type="email" placeholder="example123@gmail.com" onChange={e => setEmail(e.target.value)}></input>
                                </div>
                                <div className="webflow-style-input">
                                    <input className="" type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}></input>
                                </div>
                                <div className="webflow-style-input">
                                    <input className="" type="password" placeholder="Re-Type password" onChange={e => setRetypePassword(e.target.value)}></input>
                                </div>


                                <div className="register-buttons">
                                    <div className="cancel">
                                        <button className="" >
                                            <a href="/" className="text-decoration-none text-white">
                                                Go back 
                                            </a>
                                        </button>
                                    </div>
                                    <div className="submit">
                                        <button className="" onClick={handleRegister}>
                                        {
                                            showLoading ?
                                            <span class="spinner-border spinner-border-sm mx-3" role="status" aria-hidden="true"></span>
                                            :
                                            ""
                                        }
                                            Create account
                                            
                                            </button>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-6 ">
                                <div className="register-mission">
                                    <img src={ztradeLogo} />
                                    <h1>Our Vision</h1>
                                    <span>
                                        To Become one of the best trading companies in Myanmar's industrial world.
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}