import Footer from "../../shared/components/Footer";
import MiddleBar from "../../shared/components/MiddleBar";
import Navbar from "../../shared/components/Navbar";
import ProductSlider from "../../shared/components/ProductSlider";
import TopBar from "../../shared/components/TopBar";
import ProductDescription from "./components/ProductDescription";
import {
    useLocation,
    useParams
  } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { fetchCategories } from "../../redux/actions/category_actions";
import { fetchProducts, fetchSingleProduct } from "../../redux/actions/product_actions";
import ProductDescriptionLinkShare from "./components/ProductDescriptionLinkShare";



export default function ProductDetail(){

    const dispatch = useDispatch();
    let { productId } = useParams();
     

    // const categories = useSelector((state) => state.categories.categories);
    const location = useLocation()
    const { data } = location.state ? location.state : ""
    const products = useSelector((state) => state.product.products);
    const isProductLoading = useSelector((state) => state.product.loading);
    const productLoading = useSelector((state) => state.singleProduct.loading);
    const singleProduct = useSelector((state) => state.singleProduct.product);
    useEffect(()=>{
        dispatch(fetchCategories())
        dispatch(fetchProducts())
        dispatch(fetchSingleProduct(productId))
        
    },[productId])

    
    return(
        <>
        <div>            
            {/* <TopBar/>
            <MiddleBar/>
            <Navbar/> */}
            {/* {location.state == null ? "ture" : "false"} */}
            {
                location.state == null
                ?
                <>
                    {productLoading 
                    ?
                    "Loading"
                    :
                    <ProductDescriptionLinkShare/>
                    }
                </>
                :
                <ProductDescription productId={productId} product={data}/>
            }
            <ProductSlider title="Related Products" carouselID="4" products={products} isLoading={isProductLoading}/>
            <Footer/>
        </div>
        </>
    )
}