import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSlider } from "../../redux/actions/slider_actions";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

export default function Slider({ isBrand, brandSlider, sliderData }) {

    const dispatch = useDispatch();

    const sliders = useSelector((state) => state.slider.sliders);


    useEffect(() => {
        if (!isBrand) {
            dispatch(fetchSlider())
        }

    }, [])

    const state = {
        responsive: {
            0: {
                items: 1,
                nav: 0
            },
            450: {
                items: 1,
                nav: 0,
                
            },
            600: {
                items: 1,
                nav: 0,
                
            },
            1000: {
                items: 1,
                nav: 0
            },
        },
    }

    return (
        <>
            {
                !isBrand ?

                    <>
                        <OwlCarousel className='owl-theme' nav={0} dots={1} autoplay={1} loop={1} responsive={state.responsive}>
                            {
                                sliderData.map((slider, index) => (
                                    slider.store == null ?
                                    <div className="d-block w-100 slider-image" style={{
                                        backgroundImage:

                                            "url(" + `${process.env.REACT_APP_PRODUCTION_PORT}storage/slider_image/${slider.image})`

                                        ,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",

                                    }}>

                                    </div>
                                    :
                                    ""
                                ))
                            }
                        </OwlCarousel>

                    </>

                    :

                    <>


                        <OwlCarousel className='owl-theme' nav={0} dots={1} autoplay={1} loop={1} responsive={state.responsive}>
                            {
                                brandSlider.map((slider, index) => (
                                    <div className="d-block w-100 slider-image" style={{
                                        backgroundImage:

                                            "url(" + `${process.env.REACT_APP_PRODUCTION_PORT}storage/slider_image/${slider.image})`

                                        ,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",

                                    }}>
                                        {/* <img src={process.env.REACT_APP_STATUS === "development" ?
                                            `http://127.0.0.1:8000/storage/slider_image/${slider.image}`
                                            :
                                            `${process.env.REACT_APP_PRODUCTION_PORT}storage/slider_image/${slider.image}`}
                                            
                                        class="d-block w-100" alt="..." style={{maxHeight : "550px"}}/> */}
                                    </div>
                                ))
                            }
                        </OwlCarousel>
                      
                    </>

            }

        </>
    );
}