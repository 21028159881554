import { ActionTypes } from "../actions/types"

const productInitialState ={
    loading : true,
    products: [],
    product : [],
    error : false,
    errorMessage : []
}

const mostPopularInitialState ={
    loading : true,
    mostPropularProducts: [],   
    error : false,
    errorMessage : []
}

const singlePorductInitialState = {
    loading : true,
    product : [],
    error : false,
    errorMessage : []

}

const newArrivalInitialState ={
    loading : true,
    newArrivalProducts: [],   
    error : false,
    errorMessage : []
}

export const ProductReducer = (state = productInitialState,{type,payload}={})=>{
    switch(type){
        case ActionTypes.FETCH_PRODUCTS:
            return {
                ... state,
                loading : false,
                products  : payload
            }
            case ActionTypes.FETCH_SINGLE_PRODUCT:
                return {
                    ... state,
                    loading : false,
                    product  : payload
                }
        case ActionTypes.ERROR_MESSAGE:
            return {
                ... state,
                loading : false,
                error : true,
                errorMessage : payload
            }

        default:
            return state;
    }
}

export const SingleProductReducer = (state = singlePorductInitialState,{type,payload}={})=>{
    switch(type){
        case ActionTypes.FETCH_SINGLE_PRODUCT:
            return {
                ... state,
                loading : false,
                product  : payload
            }

        default:
            return state;
    }
}

export const MostPopularReducer = (state = mostPopularInitialState,{type,payload}={})=>{
    switch(type){
        case ActionTypes.FETCH_MOST_POPULAR:
            return {
                ... state,
                loading : false,
                mostPropularProducts  : payload
            }

        default:
            return state;
    }
}

export const NewArrivalReducer = (state = newArrivalInitialState,{type,payload}={})=>{
    switch(type){
        case ActionTypes.FETCH_NEW_ARRIVAL:
            return {
                ... state,
                loading : false,
                newArrivalProducts  : payload
            }

        default:
            return state;
    }
}