import ztrade_api from "../../api/ztrade_api";
import { ActionTypes } from "./types";



export const fetchStores = () => async (dispatch) => {
    const response = await ztrade_api.get("nonrole/store/list")
    .then(res=>{
      dispatch({type:ActionTypes.FETCH_STORES,payload:res.data   })
    }).catch(err=>{
      dispatch({type:ActionTypes.FETCH_STORES,payload:[]  })
    });    
}

export const fetchSingleStore = (id) => async (dispatch) => {
  const response = await ztrade_api.get("nonrole/store/showwithproduct/"+id)
  .then(res=>{
    dispatch({type:ActionTypes.FETCH_SINGLE_STORE,payload:res.data   })
  }).catch(err=>{
    dispatch({type:ActionTypes.FETCH_SINGLE_STORE,payload:[]  })
  });    
}



export const tokenConfigFile =() =>{

  // const userToken = JSON.parse(token)
  const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  // if(userToken) {
  //     config.headers['Authorization'] = `Bearer ${userToken}`;
  // }

  return config;
}



