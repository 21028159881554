
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import ShopProductCard from './ShopProductCard';
import { fetchMostPopular, fetchProducts } from '../../redux/actions/product_actions';

export default function MostPopular({carouselID,mostPopularProduct}) {

    const state= {
        responsive:{
            0: {
                items: 1,
                nav : 0
            },
            450: {
                items: 1,
               nav: 0
            },
            600: {
                items: 3,
                nav:1
            },
            1000: {
                items: 6,
                nav:1
            },
        },
    }
    const dispatch = useDispatch();
    // const mostPopularProducts = useSelector((state) => state.mostPopular.mostPropularProducts);
    // const isLoading = useSelector((state) => state.mostPopular.loading);
    useEffect(()=>{
        // dispatch(fetchMostPopular())
    },[])

    return (

        <>
            {


                <div className="container mt-4">

                    <div className="row">
                        <div className="col-lg-6 d-flex justify-content-start ">
                            
                            <h3 className=''>
                                <i class=" custom-color fa-solid fa-square "></i> Most Popular
                            </h3>
                            
                        </div>

                    
                        
                        {/* {
                            mostPopularProduct.length <=0 
                            ?
                            <div>
                                No Data for Most Popular
                            </div>
                            :
                            <div className="col-lg-6">
                                <div className="d-flex justify-content-end">
                                <div className="me-4">
                                    <button className={`ms-1 carousel-button ${ mostPopularProduct.length <=8 ? 'disabled' : ''}`} href={`${mostPopularProduct.length <=8 ? '' : '#carouselExampleControls' + carouselID}`} role="button" data-slide="prev" >
                                        <i class="fa-solid fa-angle-left text-white ms-2 me-2"></i>
                                    </button>
                                    <button className={`ms-1 carousel-button mr-5 ${ mostPopularProduct.length <=8 ? 'disabled' : ''}`} href={`${mostPopularProduct.length <=8 ? '' : '#carouselExampleControls' + carouselID}`} role="button" data-slide="next">
                                        <i class="fa-solid fa-angle-right text-white ms-2 me-2"></i>
                                    </button>
                                </div>

                            
                                </div>

                            </div>
                        } */}
                        
                    </div>

                    <hr/>
                    

                    <div className="container">
                    {
                        mostPopularProduct.length === 0 ?
                        "  No Data for Most Popular"
                        :
                        <OwlCarousel navText={
                            [  '<span class="owl-prev btn btn-primary-color">‹</span>',
                                '<span class="owl-next btn btn-primary-color">›</span>']
                            } 
                            
                            nav={1} dots={1} className='' loop={mostPopularProduct.length <= 7 ? false : true} items={mostPopularProduct.length <= 5 ? mostPopularProduct.length : 5} 
                            responsive={state.responsive}
                            
                            >

                                {
                                    mostPopularProduct.map((product, index) => (
                                        <div key={product.id} className="item">
                                            <ShopProductCard product={product} index={index} />
                                        </div>
                                    ))
                                }

                            </OwlCarousel>
                    }

                    </div>



                    {/* <div id={"carouselExampleControls" + carouselID} class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="cards-wrapper">
                                    {
                                        mostPopularProduct.slice(0, 8).map((product, index) => (
                                            <div key={product.id} className="">
                                                <ShopProductCard product={product} index={index} />
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                            <div class="carousel-item">
                                <div class="cards-wrapper">
                                    {
                                        mostPopularProduct.slice(6, 15).map((product, index) => (
                                            <div key={product.id} className="">
                                                <ShopProductCard product={product} index={index} />
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        </div>


                    </div> */}
                </div>

            }

        </>
    )
}