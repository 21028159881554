import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { fetchStores } from "../../redux/actions/store_actions";
import Footer from "../../shared/components/Footer";
import BrandList from "./components/BrandLists";


export default function BrandLists(){
    const stores = useSelector((state) => state.store.stores);
    const isLoading = useSelector((state) => state.store.loading);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(fetchStores())
    },[])

    return(
<>
            {/* <TopBar />
            <MiddleBar />
            <Navbar /> */}
            <div style={{backgroundColor : "hsl(0deg 0% 96%)"}}>
                <div className="container mt-2">
                    <div className="row">
                        <div className="col-lg-3 padding-left ms-3 mt-3">
                            {
                                isLoading?
                                "Loading"
                                :
                                <BrandList brands={stores}/>
                            }

                            
                        </div>
                        <div className="col-lg-8">

                        <div className="container">
                <div className="row">
                    <div class="container-fluid mt-3">
                        
                        <div class="row ">
                            {
                                stores.map((data, index) => {
                                    return (
                                        <div class="col-lg-3 col-md-6 col-sm-4 pb-1">
                                            <div class="cat-item d-flex flex-column mb-4 shadow-sm" style={{ padding: "30px", backgroundColor: "#fff" }}>

                                                <Link to={`/brand/detail/${data.id}`} class="cat-img position-relative overflow-hidden mb-3">
                                                    <img className="img-fluid" src={
                                                        
                                                        process.env.REACT_APP_STATUS === "development" ?
                                                        `${process.env.REACT_APP_DEVELOPMENT_PORT}storage/store_image/${data.image}`
                                                        :
                                                        `${process.env.REACT_APP_PRODUCTION_PORT}storage/store_image/${data.image}`

                                                    } alt={data.brand_name} />
                                                </Link>
                                                <h5 class="m-0 text-center text-truncate">{data.brand_name} </h5>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>
                         
                        </div>
                    </div>
                    
                </div>
            </div>
            <Footer/>
        </>
    )
}