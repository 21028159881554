import ztrade_api from "../../api/ztrade_api";
import { ActionTypes } from "./types";

export const index = () => async (dispatch) => {
    const response = await ztrade_api.get("api/ztrade/index")
    .then(res=>{
      dispatch({type:ActionTypes.INDEX,payload:res.data   })
    }).catch(err=>{
      dispatch({type:ActionTypes.INDEX,payload:[]  })
    });    
  }